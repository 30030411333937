import Home from "./pages/Home";
import BehindTheScenes from "./pages/BehindTheScenes";

import ReactGA from "react-ga4";
import { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";

import "./App.scss";

// Initialize GA4 with your measurement ID
ReactGA.initialize("G-M2XLH16C3N");

function PageTracker() {
  const location = useLocation();
  
  useEffect(() => {
    ReactGA.send("pageview");
  }, [location]);

  return null;
}

function App() {
  return (
    <Router>
      <PageTracker />
      <Switch>
        <Route path="/behind-the-scenes">
          <BehindTheScenes />
        </Route>
        <Route exact path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
