import React from "react";
import { Link } from "react-router-dom";
import "./styles.scss";

const About = ({ data, setSelectedTeammate }) => {
  return (
    <div className="aboutUs" id="about">
      <div className="aboutUsOverlayWrapper">
        <div className="aboutUsOverlayContainer">
          <div className="aboutUsOverlay">
            <div className="aboutUsOverlaySection">
              <Link 
                to={{
                  pathname: "/behind-the-scenes",
                  state: { navigatedInternally: true }
                }}
                className="aboutUsOverlaySectionParagraph"
              >
                WATCH BEHIND THE SCENES
              </Link>
            </div>
          </div>
        </div>
        <div className="caramelOverlay">
          <div style={{ height: 240, width: "100%" }} />
        </div>
      </div>
    </div>
  );
};

export default About;
