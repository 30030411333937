import React, { useEffect, useState } from 'react';
import { getBehindTheScenes } from '../../network/ApiAxios';
import Masonry from 'react-masonry-css';
import NavBar from '../../components/Navbar';
import './styles.scss';
import config from "../../network/config";

function BehindTheScenes() {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await getBehindTheScenes();
        console.log('Images response:', response.data); // Add this line
        setImages(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching images:', error);
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  const breakpointColumns = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1
  };

  if (loading) return (
    <>
      <NavBar />
      <div className="loading-state">Loading...</div>
    </>
  );

  return (
    <>
      <NavBar />
      <div className="behind-scenes">
        <Masonry
          breakpointCols={breakpointColumns}
          className="behind-scenes-grid"
          columnClassName="behind-scenes-grid_column"
        >
          {images.map((item, index) => (
            <div key={item.id} className="image-item">
              <img 
                src={`${config.WS_STORAGE_URL}/${item.filename_disk}`} 
                alt={item.title || "Behind the scenes"}
                loading="lazy"
                style={{ animationDelay: `${index * 0.1}s` }}
              />
            </div>
          ))}
        </Masonry>
      </div>
    </>
  );
}

export default BehindTheScenes;